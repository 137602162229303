<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-2">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-10">
          <h3 class="font-weight-bold mb-1">
            {{ $t('ledger') }}
          </h3>

          Book Account
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import SideBar from './component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>
